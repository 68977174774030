import PropTypes from "prop-types"
import React from "react"
import Stack from "./stack"
import Box from "./box"
import Inline from "./inline"

const TextPlaceholder = ({ words, lines, paragraphs, children }) => {
  const wordsPerParagraph = Array.isArray(words)
    ? words
    : Array(paragraphs).fill(Array(lines).fill(words))

  return (
    <Stack space={12}>
      {Array(paragraphs)
        .fill()
        .map((_, paragraph) => (
          <Stack key={`paragraph${paragraph}`} space={6}>
            {Array(lines)
              .fill()
              .map((_, line) => (
                <Box key={`line${line}`}>
                  {children({
                    paragraph,
                    line,
                    lineContent:
                      wordsPerParagraph[paragraph][line] > 1 ? (
                        <Inline space={3}>
                          {Array(wordsPerParagraph[paragraph][line])
                            .fill()
                            .map((_, index) => {
                              return (
                                <Box
                                  key={`word${index}`}
                                  bg="border"
                                  sx={{ width: 12, height: 4 }}
                                />
                              )
                            })}
                        </Inline>
                      ) : (
                        <Box bg="border" sx={{ width: "100%", height: 4 }} />
                      ),
                  })}
                </Box>
              ))}
          </Stack>
        ))}
    </Stack>
  )
}

TextPlaceholder.propTypes = {
  words: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  lines: PropTypes.number,
  paragraphs: PropTypes.number,
}

TextPlaceholder.defaultProps = {
  words: 1,
  lines: 1,
  paragraphs: 1,
}

export default TextPlaceholder
