/** @jsx jsx */

import React from "react" // eslint-disable-line no-unused-vars
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Box from "./box"
import Icon from "./icon"
import Label from "./label"
import Stack from "./stack"
import FormMessage from "./form-message"

/**
 * Before using a select menu, consider using a set of radio buttons instead.
 * This will work way better in most scenarios. Should only be considered if
 * you have a lot of options to show or very limited space.
 */
const Select = React.forwardRef((props, ref) => {
  const { id, label, bold, message, tone, ...restProps } = props

  return (
    <Stack space={2}>
      {label && (
        <Label htmlFor={id} bold={bold}>
          {label}
        </Label>
      )}
      <Box display="flex">
        <Box
          ref={ref}
          id={id}
          as="select"
          sx={{
            display: "block",
            width: "100%",
            p: 2,
            appearance: "none",
            fontSize: "inherit",
            lineHeight: "inherit",
            border: "1px solid",
            borderColor: tone === "negative" ? "negative" : "border",
            borderRadius: "md",
            color: "inherit",
            bg: "transparent",
            variant: "forms.select",
            "> option": {
              color: "initial",
            },
          }}
          {...restProps}
        />
        <Icon
          icon="dropdown"
          sx={{
            color: "whisper",
            alignSelf: "center",
            pointerEvents: "none",
            ml: -8,
            ":hover": {
              color: "primary",
            },
          }}
        />
      </Box>
      {message && <FormMessage tone={tone}>{message}</FormMessage>}
    </Stack>
  )
})

Select.propTypes = {
  /**
   * unique id for the select. when a label is provided this will automatically
   * be used as the `for` attribute
   */
  id: PropTypes.string.isRequired,
  /**
   * Label for the textarea. Make sure to skip this only when you are providing
   * an alternative label (and have a good reason to break our design
   * conventions around labels on top of inputs/textareas)
   */
  label: PropTypes.node,
  /**
   * this controls wether the label should be bold or not
   */
  bold: PropTypes.bool,
  /**
   * optional message which is displayed beneath the input. used for error
   * messages and instructions
   */
  message: PropTypes.string,
  /**
   * text color and icon are based on the tone of the message
   */
  tone: PropTypes.oneOf(["positive", "neutral", "negative"]),
}

Select.defaultProps = {
  height: 12,
  bold: true,
}

export default Select
